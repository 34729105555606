import { create } from 'zustand'
import { getMyOrganizations } from '../api/organization'
import { MyOrganizations } from '../utils/types/organization'

export interface OrganizationStoreState {
	myOrganizations: MyOrganizations[]
	getMyOrganizations: () => Promise<void>
}

export const organizationStore = create<OrganizationStoreState>(
	(set) => ({
		myOrganizations: [],
		getMyOrganizations: async () => {
			const myOrganizations = await getMyOrganizations()
			set({ myOrganizations })
		}
	})
)
