import { FC } from 'react'
import { Col, Row } from 'antd'
import {
	// MessageOutlined,
	CalendarOutlined,
	HistoryOutlined,
	UserOutlined
} from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import {
	QUEUE_SCHEME_ROUTE,
	MONITORING_ROUTE,
	PROFILE_ROUTE
	// CHAT_ROUTE
} from '../utils/routenames'
import { useTranslation } from 'react-i18next'

interface NavbarProps {}

export const Navbar: FC<NavbarProps> = () => {
	const { t } = useTranslation()
	const location = useLocation()

	const checkRoute = (route: string) => {
		return location.pathname === route ? 'click' : ''
	}

	const navbarTabs: {
		name: string
		route: string
		icon: any
	}[] = [
		{
			name: t('Queues'),
			route: QUEUE_SCHEME_ROUTE,
			icon: CalendarOutlined
		},
		// {
		// 	name: t('Chat'),
		// 	route: CHAT_ROUTE,
		// 	icon: MessageOutlined
		// },
		{
			name: t('Monitoring'),
			route: MONITORING_ROUTE,
			icon: HistoryOutlined
		},
		{
			name: t('Profile'),
			route: PROFILE_ROUTE,
			icon: UserOutlined
		}
	]

	return (
		<div className="navbar">
			<Row justify="space-between">
				{navbarTabs.map((tab) => {
					return (
						<Link to={tab.route} key={tab.name}>
							<Col className={checkRoute(tab.route)}>
								<tab.icon
									style={{
										fontSize: 30,
										marginBottom: 5
									}}
								/>
								<p style={{ fontSize: 13 }}>
									{t(tab.name)}
								</p>
							</Col>
						</Link>
					)
				})}
			</Row>
		</div>
	)
}
