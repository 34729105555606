import { FC } from 'react'
import '../styles/Chats.scss'
import { Link } from 'react-router-dom'
import { CHAT_ROUTE } from '../utils/routenames'
import { chatStore } from '../store/chat'
import icon from '../icons/iconVerified.png'
import { useTranslation } from 'react-i18next'

export const Chats: FC = () => {
	const { chat } = chatStore()

	const { t } = useTranslation()

	return (
		<>
			<header>
				<h2 className="mainTopTitle">{t('Chats')}</h2>
			</header>
			<main className="ChatMain">
				{chat.map((data) => (
					<Link
						to={CHAT_ROUTE + '/' + data.id}
						key={data.id}
						className="ChatBody">
						<div className="ChatImgContainer">
							<img
								className="ChatImg"
								src={
									'https://ewp-test.s3.ap-southeast-1.amazonaws.com/5625b68e-34ae-41f0-92e2-a19014d28384.webp'
								}
								alt="chat1"
							/>
							<img
								className="ChatImg2"
								src={icon}
								alt="chat2"
							/>
						</div>
						<div className="ChatName">
							{data.chatUsers.map((users) => {
								return (
									<p className="ChatNameText">
										{users.firstName}{' '}
										{users.lastName}
									</p>
								)
							})}
						</div>
						<div className="ChatMin">
							<p className="ChatMinNum">
								2 {t('MinAgo')}
							</p>
							<div className="ChatUnreadCon">
								<div className="ChatUnread">
									{data.chatMessageUnreadedCount}
								</div>
							</div>
						</div>
					</Link>
				))}
			</main>
		</>
	)
}
