import { create } from 'zustand'
import {
	changeLang,
	changeUserInfo,
	changeUserName,
	changeUserNumber,
	checkUsername,
	isAuthActionsChangable,
	login,
	logout,
	refresh,
	register,
	uploadImage,
	userinfo
} from '../api/auth'
import { Token, User } from '../utils/types/user'

export interface AuthState {
	user: User | null
	isLoggedIn: boolean
	isFetched: boolean
	isAuthChangesDisabled: {
		type: string
		nextAvailableTime: string
	}[]
	login: (username: string, password: string) => Promise<Token>
	register: (
		username: string,
		password: string,
		firstname: string,
		lastname: string
	) => Promise<Token>
	logout: () => void
	refresh: () => Promise<void>
	userinfo: () => Promise<User | null>
	changeLang: (langId: string) => Promise<void>
	uploadImage: (form: FormData) => Promise<string>
	changeUserNumber: (body: { loginphone: string }) => Promise<void>
	changeUserInfo: (body: {
		firstName: string
		middleName: string
		lastName: string
	}) => Promise<void>
	checkUsername: (username: string) => Promise<{
		message:
			| 'Available'
			| 'NotValid'
			| 'NotAvailable'
			| 'InvalidLength'
	}>
	changeUserName: (body: { username: string }) => Promise<void>
	isAuthActionsChangable: () => Promise<void>
}

export const authStore = create<AuthState>()((set) => ({
	isLoggedIn: false,
	user: null,
	isFetched: false,
	isAuthChangesDisabled: [],

	login: async (username: string, password: string) => {
		const data = await login(username, password)
		set(() => ({ isLoggedIn: true, isFetched: true }))
		return data
	},

	register: async (
		username: string,
		password: string,
		firstname: string,
		lastname: string
	) => {
		const data = await register(
			username,
			password,
			firstname,
			lastname
		)
		set(() => ({ isLoggedIn: true, isFetched: true }))
		return data
	},
	logout: async () => {
		await logout()
		set(() => ({
			user: null,
			isLoggedIn: false,
			isFetched: true
		}))
	},
	refresh: async () => {
		const data = await refresh()
		set(() => ({
			isLoggedIn: true,
			user: data,
			isFetched: true
		}))
	},
	userinfo: async () => {
		try {
			const data = await userinfo()
			set(() => ({
				isLoggedIn: true,
				user: data,
				isFetched: true
			}))
			return data
		} catch (e) {
			set(() => ({
				isLoggedIn: false,
				user: null,
				isFetched: true
			}))
			return null
		}
	},
	changeLang: async (langId) => {
		await changeLang(langId)
		set((state) => {
			if (!state.user) return state
			return {
				...state,
				user: {
					...state.user,
					langId
				}
			}
		})
	},
	uploadImage: async (form) => {
		const image = await uploadImage(form)
		return image
	},

	changeUserNumber: async ({ loginphone }) => {
		await changeUserNumber({
			loginphone
		})
		set((state) => {
			const today = new Date()

			if (!state.user) return state
			return {
				...state,
				user: {
					...state.user,
					loginphone
				},
				isAuthChangesDisabled: [
					...state.isAuthChangesDisabled,
					{
						type: 'numberChange',
						nextAvailableTime: new Date(
							today.setDate(today.getDate() + 7)
						).toString()
					}
				]
			}
		})
	},

	isAuthActionsChangable: async () => {
		const isAuthChangesDisabled = await isAuthActionsChangable()

		set((state) => {
			return {
				...state,
				isAuthChangesDisabled
			}
		})
	},

	changeUserInfo: async ({ firstName, middleName, lastName }) => {
		await changeUserInfo({
			firstName,
			middleName,
			lastName
		})

		set((state) => {
			const today = new Date()

			if (!state.user) return state
			return {
				...state,
				user: {
					...state.user,
					firstName,
					middleName,
					lastName
				},
				isAuthChangesDisabled: [
					...state.isAuthChangesDisabled,
					{
						type: 'namesChange',
						nextAvailableTime: new Date(
							today.setDate(today.getDate() + 7)
						).toString()
					}
				]
			}
		})
	},

	checkUsername: async (username) => {
		const message = await checkUsername(username)
		return message
	},

	changeUserName: async ({ username }) => {
		await changeUserName({
			username
		})

		set((state) => {
			const today = new Date()
			if (!state.user) return state
			return {
				...state,
				user: {
					...state.user,
					username
				},
				isAuthChangesDisabled: [
					...state.isAuthChangesDisabled,
					{
						type: 'usernameChange',
						nextAvailableTime: new Date(
							today.setDate(today.getDate() + 7)
						).toString()
					}
				]
			}
		})
	}
}))
