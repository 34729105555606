import { FC } from 'react'

interface SearchSubmitProps {
	clicked: boolean
}

export const SearchSubmit: FC<SearchSubmitProps> = ({ clicked }) => {
	return (
		<svg
			width="28"
			height="24"
			viewBox="0 0 28 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M27.185 13.1722C28.2272 12.7382 28.2272 11.2618 27.185 10.8278V10.8278L2.7346 0.479896V0.479896C1.70648 0.0391782 0.652932 1.04933 1.04559 2.09675V2.09675L4.74532 12L1.04559 21.9032V21.9032C0.652931 22.9506 1.70648 23.9608 2.7346 23.5201V23.5201L27.185 13.1722V13.1722Z"
				fill="url(#paint0_linear_1_241)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_1_241"
					x1="25.7746"
					y1="21.3553"
					x2="9.13897"
					y2="-0.489483"
					gradientUnits="userSpaceOnUse">
					<stop
						stopColor={clicked ? '#C0E91C' : '#979791'}
					/>
					<stop
						offset="1"
						stopColor={clicked ? '#73CC2C' : '#979791'}
					/>
				</linearGradient>
			</defs>
		</svg>
	)
}
