export const formatDate = (date: Date | string, format: string) => {
	const d = new Date(date)

	const map: Record<string, string> = {
		YYYY: String(d.getFullYear()),
		MM: String(d.getMonth() + 1).padStart(2, '0'),
		DD: String(d.getDate()).padStart(2, '0'),
		HH: String(d.getHours()).padStart(2, '0'),
		mm: String(d.getMinutes()).padStart(2, '0'),
		ss: String(d.getSeconds()).padStart(2, '0'),
		ms: String(d.getMilliseconds()).padStart(3, '0')
	}

	return format.replace(
		/YYYY|MM|DD|HH|mm|ss|ms/g,
		(match) => map[match]
	)
}

export const formatTime = (date: Date | string, format: string) => {
	const d = new Date(new Date().toJSON().split('T')[0] + 'T' + date)

	const map: Record<string, string> = {
		HH: String(d.getHours()).padStart(2, '0'),
		mm: String(d.getMinutes()).padStart(2, '0'),
		ss: String(d.getSeconds()).padStart(2, '0'),
		ms: String(d.getMilliseconds()).padStart(3, '0')
	}

	return format.replace(/HH|mm|ss|ms/g, (match) => map[match])
}
