import {
	useEffect,
	useRef,
	Dispatch,
	SetStateAction,
	KeyboardEventHandler
} from 'react'
import { useTranslation } from 'react-i18next'

export const ChatOneInput = ({
	chatMessageValue,
	setChatMessageValue,
	handleKeyDown
}: {
	setChatMessageValue: Dispatch<SetStateAction<string>>
	chatMessageValue: string
	handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement>
}) => {
	const inputRef = useRef<HTMLTextAreaElement>(null)

	const { t } = useTranslation()

	useEffect(() => {
		const input = inputRef.current
		const rootStyle = document.getElementById('root')?.style
		if (!rootStyle) return
		if (input) {
			input.style.height = 'auto'
			input.style.height = `${input.scrollHeight}px`
			rootStyle.setProperty(
				'--chatone-main-height',
				`${input.scrollHeight < 245 ? input.scrollHeight : 245}px`
			)
		}
	}, [chatMessageValue])

	const handleChange = (event: any) => {
		setChatMessageValue(event.target.value)
	}

	return (
		<textarea
			autoFocus
			ref={inputRef}
			className="ChatOneInput"
			value={chatMessageValue}
			onChange={handleChange}
			rows={1}
			onKeyDown={handleKeyDown}
			placeholder={t('WriteMessage')}
		/>
	)
}
