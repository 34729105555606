import { useState, useEffect, FC } from 'react'
// import NavbatLogo from '../icons/logoNavbat.svg'
import questionIcon from '../icons/question.svg'
import { Link } from 'react-router-dom'
import { Search } from '../components/Search'
import moment from 'moment'
import '../styles/Home.scss'
import Tip from '../components/Tip'
import { useTranslation } from 'react-i18next'

export const Home: FC = () => {
	const { t } = useTranslation()

	const [clientQueues] = useState([
		{
			queueId: '123',
			inner: {
				name: 'Test',
				activeWorkTime: [1000, 2000],
				active: 12,
				message: 'Test message'
			},
			number: 12,
			createdAt: '2021-08-22T10:00:00.000Z'
		}
	])
	const [loading] = useState(false)
	const [showtip, setShowtip] = useState(false)

	// const reg = location.search.includes('?reg')

	// useEffect(() => {
	// 	if (
	// 		// !moment(user.lastVisit).isAfter(
	// 		// 	moment().subtract(90, 'days')
	// 		// ) ||
	// 		// reg
	// 		false ||
	// 		true
	// 	) {
	// 		setShowtip(true)
	// 	} else {
	// 		setShowtip(false)
	// 	}
	// }, [reg, location.search])

	useEffect(() => {
		// ClientGetHomeAll()
		// 	.then((data) => setClientQueues(data))
		// 	.finally(() => setLoading(false))
	}, [])

	if (loading) {
		return (
			<center>
				<h2>{'Loading'}...</h2>
			</center>
		)
	}

	document.body.removeAttribute('style')

	return (
		<>
			{showtip ? (
				<Tip onAction={() => setShowtip(false)} />
			) : null}
			<header className="home">
				<Search />
				<section className="textCont">
					<p className="m-0">{t('YourQueues')}</p>
				</section>
			</header>
			<main className="home">
				{clientQueues.length ? (
					clientQueues.map((queue) => (
						<section
							key={queue.queueId}
							className="recordsIndex"
							style={{
								display: 'flex',
								flexDirection: 'column'
							}}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}>
								<div style={{ flexGrow: 1 }}>
									<h5 className="queueName">
										{/* {queue.inner?.name}  */}
										TEST
									</h5>
									<p className="queueTime">
										{t('TheAverageInterval')}:{' '}
										{/* {queue.inner?.activeWorkTime
											? (
													queue.inner?.activeWorkTime.reduce(
														(
															a,
															b,
															_ar,
															arr
														) =>
															(a + b) /
															arr.length
													) / 1000
												).toFixed(2) +
												' мин.(сек)'
											: 'нет данных'} */}
									</p>
								</div>
								<div className="queueNum">
									<p>{t('Your')} №</p>
									<b>
										{/* {queue.number} */}
										12
									</b>
								</div>
							</div>
							<div style={{ display: 'flex' }}>
								<i className="la la-calendar-check la-2x cGray"></i>
								<div
									style={{
										flexGrow: 1,
										paddingLeft: 10
									}}>
									<p className="queueCrTimeText">
										{t('YouHaveBeenInLineSince')}
									</p>
									<b className="queueCrTime">
										{moment(
											// queue.createdAt
											'2021-08-22T10:00:00.000Z'
										).format('DD.MM.YYYY HH:mm')}
									</b>
								</div>
								<div className="queueCurrentTime">
									<p className="queueCrTimeText">
										{t('Current')} №
									</p>
									<b className="queueCrTime">
										{/* {queue.inner.active ===
										queue.number
											? 'Ваш'
											: queue.inner.active} */}
										{t('Your')}
									</b>
								</div>
							</div>
							<div style={{ display: 'flex' }}>
								<i className="la la-info la-2x cGray"></i>
								<div
									style={{
										flexGrow: 1,
										paddingLeft: 16
									}}>
									<p className="queueCrTimeText">
										{t('Note')}
									</p>
									<b className="queueCrTime">
										{/* {queue.inner?.message} */}
										Тестовая заметка
									</b>
								</div>
								<div className="queueCurrentTime">
									<p className="queueCrTimeText">
										{t('QueueNumber')}
									</p>
									<b className="queueCrTime">
										{/* {queue.queueId} */}
										123
									</b>
								</div>
							</div>
							<nav style={{ display: 'flex' }}>
								<button
									className="queueBtn error"
									style={{ marginRight: '1rem' }}
									onClick={() =>
										// Cancel(
										// 	queue.queueId,
										// 	queue.id
										// )
										console.log('Cancel')
									}>
									<i className="la la-door-open la-2x"></i>
									{t('Cancel')}
								</button>
								<button className="queueBtn bgGray">
									<i className="la la-share la-2x"></i>
									{t('Share')}
								</button>
							</nav>
						</section>
					))
				) : (
					<article className="emptyHome">
						<h3 className="emptyHomeHeader">
							{t('Dear')}{' '}
							<span
								style={{
									textTransform: 'capitalize'
								}}>
								{/* {user.name + '!'} */}
								{'User!'}
							</span>
							<br />
							{t('YouDontHaveAnyEntries')}
						</h3>
						<p className="emptyHomeText">
							Add a queue via the code or scan the QR
							code
						</p>
					</article>
				)}
			</main>
			<nav>
				<Link className="infoLogo" to="/?reg">
					<img src={questionIcon} alt="?" />
				</Link>
			</nav>
		</>
	)
}
