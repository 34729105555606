import { FC } from 'react'
import { queueSchemeStore } from '../store/queueScheme'
import '../styles/Monitoring.scss'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

export const Monitoring: FC = () => {
	const { t } = useTranslation()

	const { queueScheme } = queueSchemeStore()

	return (
		<>
			<Helmet>
				<title>{t('Monitoring')} – Queue Expert</title>
				<meta
					name="description"
					content={t('MonitoringDesc')}
				/>
			</Helmet>
			<header>
				<h2 className="mainTopTitle">{t('Monitoring')}</h2>
			</header>
			<main className="monitoringMain">
				{queueScheme.map((qs) => (
					<div key={qs.id} className="monitoringBody">
						<div className="monitoringName">
							{qs.name}
						</div>
						<div className="monitoringInfo">
							<div className="monitoringIcon">
								<i className="la la-info la-2x cGray"></i>
								<div className="monitoringDescription">
									<p>{t('Note')}</p>
									<p>{qs.description}</p>
								</div>
							</div>
							<div className="monitoringCountsUnique">
								<div className="monitoringCU">
									<div className="monitoringText">
										{t('NumberClients')}
									</div>
									<div className="monitoringNum">
										{qs.recepientsCount}
									</div>
								</div>
								<div className="monitoringCU">
									<div className="monitoringText">
										{t('QueueCode')}
									</div>
									<div className="monitoringNum">
										{qs.unique}
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</main>
		</>
	)
}
