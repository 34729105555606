import { create } from 'zustand'
import { QueueCategory } from '../utils/types/queueCategory'
import { fetchQueueCategories } from '../api/queueCategory'

export interface QueueCategoryState {
	queueCategories: QueueCategory[]
	fetchQueueCategories: () => Promise<void>
}

export const queueCategoryStore = create<QueueCategoryState>(
	(set) => ({
		queueCategories: [],
		fetchQueueCategories: async () => {
			const data = await fetchQueueCategories()
			set(() => ({ queueCategories: data }))
		}
	})
)
