import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
)

const language = localStorage.getItem('language') || 'ru'
i18next.use(initReactI18next).init({
	resources: {
		en: {
			translation: {
				'Hello World': 'Hello World',
				Loading: 'Loading',
				Queues: 'Queues',
				Chat: 'Chat',
				Monitoring: 'Monitoring',
				Profile: 'Profile',
				Home: 'Home',
				ChangeLanguage: 'Change language',
				QueueCode: 'Queue code',
				NumberOfClients: 'Number of clients',
				Current: 'Current',
				YouHaveNotQueuesCreated:
					'You have not any created queues yet',
				AddNewQueueUsingButton:
					'Add a new queue using the button',
				Queue: 'Queue',
				OpeningHours: 'Opening hours',
				Note: 'Note',
				TodayNotWork: 'Today is not work day',
				Here: 'Here',
				Cancel: 'Cancel',
				DoYouWantTo: 'Do you want to',
				PauseThis: 'Pause the Queue ?',
				EndThis: 'End the Queue ?',
				Free: 'No customers yet',
				Next: 'Next',
				NoMoreRecepients: 'No more recepients',
				Pause: 'Pause',
				End: 'End',
				PauseBlockedNextIn: 'Pause blocked, next one in',
				Skip: 'Skip',
				Chats: 'Chats',
				MinAgo: 'min ago',
				YourQueues: 'Your Queues',
				TheAverageInterval: 'The average interval',
				Your: 'Your',
				YouHaveBeenInLineSince: 'You have been in line since',
				Dear: 'Dear',
				Username: 'Username',
				Password: 'Password',
				FirstName: 'First name',
				MiddleName: 'Middle name',
				LastName: 'Last name',
				Register: 'Register',
				PhoneNumber: 'Phone number',
				MonitoringQueues: 'Monitoring queues',
				Exit: 'Exit',
				Submit: 'Submit',
				ThisUsernameAvailable: 'This user name is available',
				ThisUsernameAlreadyOccupied:
					'This username is already occupied',
				OnlyUnderscoresAllowed:
					'Only a-z,0-9 and under score allowed',
				ThisUsernameInvalid: 'This username is invalid',
				WriteMessage: 'Write a message...',
				ToGetInLine: 'To get in line',
				UseCodeQRScanner: 'use code or QR scanner',
				AllClear: 'All clear',
				Online: 'Online',
				YouDontHaveAnyEntries:
					"You don't have any entries in the queue yet",
				NumberClients: 'Number of clients',
				UserInfo: 'Full name',
				NotRatedYet: 'not rated yet',
				Reviews: 'reviews',
				ProfileDesc: 'Profile page',
				QueuesDesc: 'Queues page',
				QueueDesc: 'Queue page',
				ChangeLanguageDesc: 'Change language page'
			}
		},
		ru: {
			translation: {
				'Hello World': 'Привет мир',
				Loading: 'Загрузка',
				Queues: 'Очереди',
				Chat: 'Чат',
				Monitoring: 'Мониторинг',
				Profile: 'Профиль',
				Home: 'Главная',
				ChangeLanguage: 'Изменить язык',
				QueueCode: 'Код очереди',
				NumberOfClients: 'Количество клиентов',
				Current: 'Tекущий',
				YouHaveNotQueuesCreated:
					'У вас пока нет созданных очередей',
				AddNewQueueUsingButton:
					'Добавьте новую очередь с помощью кнопки',
				Queue: 'Очередь',
				OpeningHours: 'Часы работы',
				Note: 'Заметка',
				TodayNotWork: 'Сегодня нерабочий день',
				Here: 'Здесь',
				Cancel: 'Отменить',
				DoYouWantTo: 'Вы хотите, чтобы',
				PauseThis: 'приостановить очередь ?',
				EndThis: 'закончить очередь ?',
				Free: 'Клиентов пока нет',
				Next: 'Следующий',
				NoMoreRecepients: 'Клиентов больше нет',
				Pause: 'Временное приостановление',
				End: 'Завершить',
				PauseBlockedNextIn: 'Пауза Заблокировано, далее в',
				Continue: 'Продолжить',
				Skip: 'Пропустить',
				Chats: 'Чаты',
				MinAgo: 'мин. назад',
				YourQueues: 'Ваши очереди',
				TheAverageInterval: 'Средний интервал',
				Your: 'Ваш(а)',
				YouHaveBeenInLineSince: 'Вы в очереди с',
				Dear: 'Уважаемый(ая)',
				Username: 'Имя пользователя',
				Password: 'Пароль',
				FirstName: 'Имя',
				MiddleName: 'Отчество',
				LastName: 'Фамилия',
				Register: 'Зарегистрироваться',
				PhoneNumber: 'Номер телефона',
				MonitoringQueues: 'Мониторинг очередей',
				Exit: 'Выход',
				Submit: 'Подтвердить',
				ThisUsernameAvailable:
					'Это имя пользователя доступно',
				ThisUsernameAlreadyOccupied:
					'Это имя пользователя уже занято',
				OnlyUnderscoresAllowed:
					'Разрешены только символы a-z, 0-9 и подчеркивания',
				ThisUsernameInvalid:
					'Это имя пользователя недействительно',
				WriteMessage: 'Написать сообщение...',
				ToGetInLine: 'Чтобы занять очередь',
				UseCodeQRScanner: 'используйте код или QR сканер',
				AllClear: 'Все понятно!',
				Online: 'в сети',
				YouDontHaveAnyEntries:
					'У вас пока нет записей в очереди',
				NumberClients: 'Количество клиентов',
				UserInfo: 'Ф.И.О',
				NotRatedYet: 'нет отзывов',
				Reviews: 'отзывы',
				ProfileDesc: 'Описание страницы',
				QueuesDesc: 'Описание страницы',
				QueueDesc: 'Описание страницы',
				ChangeLanguageDesc: 'Описание страницы'
			}
		},
		uz: {
			translation: {
				'Hello World': 'Salom Dunyo',
				Loading: 'Yuklanmoqda',
				Queues: 'Navbatlar',
				Chat: 'Chat',
				Monitoring: 'Monitoring',
				Profile: 'Profil',
				Home: 'Bosh sahifa',
				ChangeLanguage: "Tilni o'zgartirish",
				QueueCode: 'Navbat kodi',
				NumberOfClients: 'Mijozlar soni',
				Current: 'Hozirgi',
				YouHaveNotQueuesCreated:
					'Sizda hali hech qanday navbat yaratilmagan',
				AddNewQueueUsingButton:
					"Tugma yordamida yangi navbat qo'shing",
				Queue: 'Navbat',
				OpeningHours: 'Ish vaqti',
				Note: 'Eslatma',
				TodayNotWork: 'Bugun ish kuni emas',
				Here: 'Shu yerda',
				Cancel: 'Chiqish',
				DoYouWantTo: 'Siz navbatni',
				PauseThis: "vaqtinchalik to'xtatishni xohlaysizmi ?",
				EndThis: 'tugatishni xohlaysizmi ?',
				Free: "Qabulda mijoz yo'q",
				Next: 'Keyingisi',
				NoMoreRecepients: "Boshqa mijozlar yo'q",
				Pause: "Vaqtinchalik to'xtatish",
				End: 'Tugatish',
				PauseBlockedNextIn:
					"vaqtinchalik to'xtatish bloklangan, keyngisi",
				Continue: 'Davom ettirish',
				Skip: "O'tkazib yuborish",
				Chats: 'Chatlar',
				MinAgo: 'min avval',
				YourQueues: 'Sizning navbatlaringniz',
				TheAverageInterval: "O'rtacha interval",
				Your: 'Sizning',
				YouHaveBeenInLineSince: 'Siz navbatda vaqtdan beri',
				Dear: 'Hurmatli',
				Username: 'Foydalanuvchi nomi',
				Password: 'Parol',
				FirstName: 'Ism',
				MiddleName: 'Otasini ismi',
				LastName: 'Familiya',
				Register: "Ro'yxatdan o'tish",
				PhoneNumber: 'Telefon raqam',
				MonitoringQueues: 'Navbatlar monitoringi',
				Exit: 'Chiqish',
				Submit: 'Tasdiqlash',
				ThisUsernameAvailable:
					'Ushbu foydalanuvchi nomi mavjud',
				ThisUsernameAlreadyOccupied:
					'Ushbu foydalanuvchi nomi band',
				OnlyUnderscoresAllowed:
					'Faqat a-z, 0-9 va pastki chiziqqa ruxsat beriladi',
				ThisUsernameInvalid: 'Bu foydalanuvchi nomi yaroqsiz',
				WriteMessage: 'Xabar yozing...',
				ToGetInLine: 'Navbatga kirish uchun',
				UseCodeQRScanner: 'kod yoki QR skanerdan foydalaning',
				AllClear: 'Tushunarli!',
				Online: 'Online',
				YouDontHaveAnyEntries:
					"Sizning navbatingizda hali mijozlar yo'q",
				NumberClients: 'Mijozlar soni',
				UserInfo: 'F.I.SH',
				NotRatedYet: 'baholanmagan',
				Reviews: 'ta sharh',
				ProfileDesc: 'Profil sahifasi',
				MonitoringDesc: 'Monitoring sahifasi',
				QueuesDesc: 'Navbatlar sahifasi',
				QueueDesc: 'Navbat sahifasi',
				ChangeLanguageDesc: "Tilni o'zgartirish sahifasi"
			}
		}
	},
	lng: language,
	fallbackLng: 'ru',
	interpolation: {
		escapeValue: false
	}
})

i18next.on('languageChanged', (lng: string) => {
	localStorage.setItem('language', lng)
})

if (!localStorage.getItem('language')) {
	localStorage.setItem('language', 'ru')
}

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
)

serviceWorkerRegistration.register()
reportWebVitals()
