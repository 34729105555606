import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { QUEUE_SCHEME_ROUTE } from '../utils/routenames'
import { queueSchemeStore } from '../store/queueScheme'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { authStore } from '../store/auth'
import { organizationStore } from '../store/organization'
import '../styles/QueueScheme.scss'
import { Helmet } from 'react-helmet'

export const QueueScheme: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { queueScheme, getQueueSchemesOrg } = queueSchemeStore()
	const { myOrganizations } = organizationStore()
	const { user } = authStore()
	const { search } = useLocation()

	const [paramId, setParamId] = useState<string | null>()
	const [orgUserFilter, setOrgUserFilter] = useState<string>()

	useEffect(() => {
		if (!user) return
		const url = new URLSearchParams(search)
		const param = url.get('from')
		if (!param) {
			setOrgUserFilter(user.id)
			navigate('?from=' + user.id, { replace: true })
			return
		}
		setOrgUserFilter(param)
	}, [user, navigate, search])

	useEffect(() => {
		if (!user) return
		const url = new URLSearchParams(search)
		const param = url.get('from')

		setParamId(param)

		const fetchData = async () => {
			if (!param || param !== user.id) {
				await getQueueSchemesOrg(param as string)
			}
		}
		fetchData()
	}, [search, user, getQueueSchemesOrg])

	const onTabChange = async (inFilterId: string) => {
		navigate('?from=' + inFilterId, { replace: true })
	}

	if (!orgUserFilter || !user) return <></>

	const tabItems: TabsProps['items'] = [
		{
			key: user.id,
			label: 'MINE'
		},
		...myOrganizations.map((org) => ({
			key: org.id,
			label: org.name
		}))
	]
	return (
		<>
			<Helmet>
				<title>{t('Queues')} – Queue Expert</title>
				<meta name="description" content={t('QueuesDesc')} />
			</Helmet>
			<header>
				<h2 className="mainTopTitle">{t('Queues')}</h2>
			</header>
			<main className="QAMain">
				{tabItems.length > 1 ? (
					<Tabs
						items={tabItems}
						centered
						onChange={onTabChange}
						defaultActiveKey={orgUserFilter}
					/>
				) : null}
				{queueScheme.filter(
					(qs) =>
						qs.organizationId === orgUserFilter ||
						qs.userId === orgUserFilter
				).length ? (
					queueScheme
						.filter(
							(qs) =>
								qs.organizationId === orgUserFilter ||
								qs.userId === orgUserFilter
						)
						.map((queue) => (
							<Link
								to={
									QUEUE_SCHEME_ROUTE +
									'/' +
									(paramId === user.id
										? ''
										: queue.organizationId +
											'/') +
									queue.id
								}
								className="QA"
								key={queue.id}>
								<div className="queueMain">
									<h5 className="queueName">
										<div
											style={{
												display: 'flex',
												gap: '10px'
											}}>
											<div>{queue.name}</div>
											{+queue.rating === 0 ? (
												<span
													style={{
														color: '#c1c1c1',
														fontSize:
															'small'
													}}>
													{t('NotRatedYet')}
												</span>
											) : (
												<>
													<div
														style={{
															color: '#c1c1c1',
															fontSize:
																'small'
														}}>
														<i
															className="las la-star"
															style={{
																color: 'gold',
																marginRight:
																	'3px'
															}}></i>
														{Math.floor(
															queue.rating *
																10
														) / 10}
													</div>
													<div
														style={{
															color: '#c1c1c1',
															fontSize:
																'small'
														}}>
														(
														{
															queue.ratesCount
														}{' '}
														{t('Reviews')}
														)
													</div>
												</>
											)}
										</div>
									</h5>
									<div className="QAProp">
										<div className="queueDataIcon flex-2 ">
											<i className="la la-calendar-plus la-2x queueIcon"></i>
											<div className="queueData">
												<div className="queueDataText">
													{t('QueueCode')}
												</div>
												<div className="queueDataInner">
													{queue.unique}
												</div>
											</div>
										</div>
										<div className="queueDataIcon queueDataClient">
											<div className="queueCurrent">
												<div className="queueCurText">
													{t(
														'NumberOfClients'
													)}
												</div>
												<div className="queueCurNumb">
													{
														queue.recepientsCount
													}
												</div>
											</div>
										</div>
										<figcaption className="queueCurrent">
											<div className="queueCurText">
												{t('Current')} №
											</div>
											<div
												className={
													!queue.active
														? 'queueCurNumb2'
														: 'queueCurNumb'
												}>
												{!queue.active
													? t('Free')
													: queue.active}
											</div>
										</figcaption>
									</div>
								</div>
								<div className="QADate">
									<i className="la la-1x la-angle-right"></i>
								</div>
							</Link>
						))
				) : (
					<article className="emptyHome">
						<h3 className="emptyHomeHeader">
							{t('YouHaveNotQueuesCreated')}
						</h3>
						<p className="emptyHomeText">
							{t('AddNewQueueUsingButton')}
						</p>
					</article>
				)}
				{/* <div className="QANew btnSubmit pl-3 pr-3">
					<button
						className="QANewBtn"
						data-submit=""
						onClick={() =>
							history.push(QUEUE_CREATE_ROUTE)
						}>
						Создать
					</button>
				</div> */}
			</main>
		</>
	)
}
