import { $authHost } from '.'
import { Token, User } from '../utils/types/user'

export const login = async (username: string, password: string) => {
	const { data } = await $authHost.post('/auth/login', {
		username,
		password
	})
	return data as Token
}

export const register = async (
	username: string,
	password: string,
	firstName: string,
	lastName: string
) => {
	const { data } = await $authHost.post('/auth/register', {
		username,
		password,
		firstName,
		lastName
	})
	return data as Token
}

export const userInfo = async () => {
	const { data } = await $authHost.get('/auth/userinfo')
	return data as User
}

export const refresh = async () => {
	const { data } = await $authHost.get('/auth/refresh')
	return data as User
}

export const userinfo = async () => {
	const { data } = await $authHost.get('/auth/userinfo')
	return data as User
}

export const logout = async () => {
	const { data } = await $authHost.delete('/auth/logout')
	return data as User
}

export const changeLang = async (language: string) => {
	const { data } = await $authHost.put('auth/change-lang', {
		language
	})
	return data
}

export const uploadImage = async (form: FormData) => {
	const { data } = await $authHost.post('auth/image', form, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return data
}

export const changeUserNumber = async ({
	loginphone
}: {
	loginphone: string
}) => {
	const { data } = await $authHost.put('auth/change-number', {
		loginphone
	})
	return data
}

export const isAuthActionsChangable = async () => {
	const { data } = await $authHost.get('auth/check-auth-actions')
	return data
}

export const changeUserInfo = async ({
	firstName,
	middleName,
	lastName
}: {
	firstName: string
	middleName: string
	lastName: string
}) => {
	const { data } = await $authHost.put('auth/change-userinfo', {
		firstName,
		middleName,
		lastName
	})
	return data
}

export const checkUsername = async (username: string) => {
	const { data } = await $authHost.post(
		'auth/check-username',
		{
			username
		},
		{ withCredentials: true }
	)
	return data
}

export const changeUserName = async ({
	username
}: {
	username: string
}) => {
	const { data } = await $authHost.put('auth/change-username', {
		username
	})
	return data
}
