import './App.scss'
import './styles/components.scss'
import { AppRouter } from './components'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import Cookies from 'universal-cookie'
import { authStore } from './store/auth'
import { LOGIN_ROUTE, LOGOUT_ROUTE } from './utils/routenames'
import { Navbar } from './components/Navbar'
import { useTranslation } from 'react-i18next'
import { queueCategoryStore } from './store/queueCategory'
import { Start } from './components/Start'
import { queueSchemeStore } from './store/queueScheme'
import { organizationStore } from './store/organization'
import { io } from 'socket.io-client'
import { languageStore } from './store/language'
import i18next from 'i18next'
import { chatStore } from './store/chat'
import songSrc from './utils/notification-sound.mp3'

function App() {
	const navigate = useNavigate()
	const location = useLocation()
	const cookie = useMemo(() => new Cookies(), [])
	const { t: translate } = useTranslation()
	const t = useMemo(() => translate, [translate])
	const song = useMemo(() => new Audio(songSrc), [])
	const {
		isLoggedIn,
		refresh,
		userinfo,
		logout,
		isFetched,
		isAuthActionsChangable
	} = authStore()

	const [firstload, setFirstLoad] = useState(true)
	const [windowHeight, setWindowHeight] = useState<number>()

	const { fetchQueueCategories } = queueCategoryStore()
	const {
		getQueueSchemes,
		setQueueStatus,
		setNextFc,
		setActivateFc,
		setQueueClientJoinFc,
		setCancelClientFc,
		setSkipClientFc
	} = queueSchemeStore()
	const { getMyOrganizations } = organizationStore()
	const { getLanguage, language } = languageStore()
	const { getChat } = chatStore()

	setTimeout(() => {
		setFirstLoad(false)
	}, 300)

	useEffect(() => {
		const init = async () => {
			if (!cookie.get('access')) {
				if (!cookie.get('refresh')) {
					// logout()
					if (!location.search.includes('path')) {
						navigate(
							LOGIN_ROUTE +
								(!(
									location.pathname ===
										LOGIN_ROUTE ||
									location.pathname === LOGOUT_ROUTE
								)
									? '?path=' + location.pathname
									: '')
						)
					} else if (
						location.search.split('?path=')[1] ===
						LOGIN_ROUTE
					) {
						navigate(LOGIN_ROUTE)
					} else {
						navigate(
							LOGIN_ROUTE +
								'?path=' +
								location.search.split('?path=')[1]
						)
					}
					return logout()
				} else {
					await refresh()
					navigate(location.pathname)
				}
			}
			try {
				const data = await userinfo()
				if (!data?.langId) return
				await i18next.changeLanguage(
					language.find((l) => l.id === data.langId)
						?.shortName || 'ru'
				)
				if (localStorage.getItem('lang') === null) {
					localStorage.setItem('lang', data.langId)
				}
				// navigate(
				// 	location.search.split('?path=')[1] ||
				// 		HOME_ROUTE
				// )
			} catch (e) {
				if (!!cookie.get('refresh')) {
					logout()
				}
			}
		}
		init()
	}, [
		cookie,
		navigate,
		location.pathname,
		location.search,
		refresh,
		userinfo,
		logout,
		language
	])

	useEffect(() => {
		if (!language) return
		i18next.on('languageChanged', (lang) => {
			// setLangId(language.find((l) => l.shortName === lang)?.id)
			localStorage.setItem(
				'lang',
				language.find((l) => l.shortName === lang)?.id || ''
			)
		})
	}, [language])

	useEffect(() => {
		if (!isLoggedIn) return
		fetchQueueCategories()
		getQueueSchemes()
		getMyOrganizations()
		getLanguage()
		getChat()
		isAuthActionsChangable()
	}, [
		fetchQueueCategories,
		getQueueSchemes,
		getMyOrganizations,
		isLoggedIn,
		getLanguage,
		getChat,
		isAuthActionsChangable
	])

	useEffect(() => {
		const rootStyle = document.getElementById('root')?.style

		if (!rootStyle) return
		const updateWindowDimensions = () => {
			const wh = window.innerHeight
			setWindowHeight(wh)
			rootStyle.setProperty(
				'--page-height',
				!isLoggedIn ? wh + 'px' : wh - 80 + 'px'
			)
			rootStyle.height =
				rootStyle.getPropertyValue('--page-height')
		}

		updateWindowDimensions()

		window.addEventListener('resize', updateWindowDimensions)

		return () =>
			window.removeEventListener(
				'resize',
				updateWindowDimensions
			)
	}, [windowHeight, isLoggedIn])

	useEffect(() => {
		if (!isLoggedIn) return

		const socket = io(
			process.env.REACT_APP_SOCKET_URL as string,
			{
				transports: ['polling'],
				withCredentials: true
			}
		)

		socket.on('queue:pause', (data) => {
			setQueueStatus(data, 'paused')
		})

		socket.on('queue:continue', (data) => {
			setQueueStatus(data, 'active')
		})

		socket.on('queue:here', (data) => {
			setActivateFc(data)
		})

		socket.on('queue:next', (data) => {
			setNextFc(data)
		})

		socket.on('queue:join', async (data) => {
			setQueueClientJoinFc(data)
			try {
				await song.play()
			} catch (e) {}
		})

		socket.on('recepient:cancel', (data) => {
			setCancelClientFc(data)
		})

		socket.on('recepient:skip', (data) => {
			setSkipClientFc(data)
		})

		return () => {
			socket.disconnect()
		}
	}, [
		navigate,
		isLoggedIn,
		setQueueStatus,
		setNextFc,
		setActivateFc,
		setQueueClientJoinFc,
		setCancelClientFc,
		setSkipClientFc,
		song
	])

	if (!isFetched) {
		return <div>{t('Loading')}...</div>
	}

	if (firstload) {
		return <Start />
	}

	return (
		<>
			<AppRouter isLoggedIn={isLoggedIn} />
			{isLoggedIn && <Navbar />}
		</>
	)
}

export default App
