import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { authStore } from '../store/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOME_ROUTE } from '../utils/routenames'
import { AxiosError } from 'axios'

const Register: FC = () => {
	const { t } = useTranslation()
	const { register } = authStore()
	const navigate = useNavigate()
	const location = useLocation()
	const [registerData, setRegisterData] = useState({
		username: '',
		password: '',
		firstname: '',
		lastname: ''
	})

	const onFinish = async (e: any) => {
		e.preventDefault()
		try {
			await register(
				registerData.username,
				registerData.password,
				registerData.firstname,
				registerData.lastname
			)

			navigate(
				location.search.replace('?path=', '') || HOME_ROUTE
			)
		} catch (err: any) {
			if (err instanceof AxiosError) {
				alert(err.response?.data.message)
			} else {
				console.log(err)
			}
		}
	}

	return (
		<div>
			<div
				style={{
					backgroundColor: '#F9FBFF'
				}}>
				<form onSubmit={onFinish}>
					<input
						type="Username"
						onChange={(e) =>
							setRegisterData({
								...registerData,
								username: e.target.value
							})
						}
					/>
					<input
						type="Password"
						onChange={(e) =>
							setRegisterData({
								...registerData,
								password: e.target.value
							})
						}
					/>
					<input
						type="Firstname"
						onChange={(e) =>
							setRegisterData({
								...registerData,
								firstname: e.target.value
							})
						}
					/>
					<input
						type="Lastname"
						onChange={(e) =>
							setRegisterData({
								...registerData,
								lastname: e.target.value
							})
						}
					/>
					<button>{t('Register')}</button>
				</form>
			</div>
		</div>
	)
}

export default Register
