import React, { CSSProperties } from 'react'
import { NavbatLogoSmall } from '../icons/NavbatLogoSmall'
import { NavbatText } from '../icons/NavbatText'
import { KutmangText } from '../icons/KutmangText'

export function Start() {
	const style: { [key: string]: CSSProperties } = {
		main: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: window.innerHeight - 80 + 'px',
			background: '#fffff5',
			overflow: 'hidden',
			position: 'relative',
			zIndex: '1'
			// animation: 'startAnimate 1.5s infinite'
		},
		logoImg: {
			width: '35vw',
			maxWidth: '200px',
			height: 'auto',
			animation: 'startAnimate 1.5s infinite'
		},
		logoMainText: {
			margin: '15px 0 5px',
			animation: 'startAnimate 1.5s infinite'
		},
		logoSubText: {
			animation: 'startAnimate 1.5s infinite'
		}
	}

	return (
		<>
			<main style={style.main}>
				<NavbatLogoSmall style={style.logoImg} />
				<NavbatText style={style.logoMainText} />
				<KutmangText style={style.logoSubText} />
			</main>
			<style>{`#navbat-root{margin-bottom:0}`}</style>
		</>
	)
}
