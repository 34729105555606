import { FC, useState } from 'react'
import { languageStore } from '../store/language'
import { useTranslation } from 'react-i18next'
import { authStore } from '../store/auth'
import { useNavigate } from 'react-router-dom'
import '../styles/ChangeLang.scss'
import { Helmet } from 'react-helmet'

export const ChangeLang: FC = () => {
	const { t, i18n } = useTranslation()
	const { language } = languageStore()
	const { user, changeLang } = authStore()
	const navigate = useNavigate()

	const [changeLangBT, setChangeLangBT] = useState(
		language.find((lang) => lang.id === user!.langId)
	)

	const changeLanguageFc = async () => {
		if (changeLangBT!.id !== user!.langId) {
			await changeLang(changeLangBT!.id)
			await i18n.changeLanguage(changeLangBT!.shortName)
		}
		navigate(-1)
	}

	return (
		<>
			<Helmet>
				<title>{t('ChangeLanguage')} – Queue Expert</title>
				<meta
					name="description"
					content={t('ChangeLanguageDesc')}
				/>
			</Helmet>
			<header className="CHLHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.back()}></i>
				<h2 className="mainTopTitle">
					{t('ChangeLanguage')}
				</h2>
			</header>
			<main className="CHLMain">
				<div className="CHLdiv">
					{language.map((lang) => (
						<label className="w-100 cabinetRow dark flex-row">
							<button
								onClick={() => setChangeLangBT(lang)}
								className="CHLButtonLang"
								disabled={user?.langId === lang.id}>
								<img
									className="CHLImage"
									src={lang.flag}
									srcSet={lang.flag}
									alt={'Flag of ' + lang.name}
								/>
								<span>{lang.name}</span>
							</button>

							{changeLangBT?.id === lang.id && (
								<i
									className="las la-check"
									style={{ fontSize: 24 }}></i>
							)}
						</label>
					))}
				</div>

				<button
					style={{}}
					className="btnSubmit"
					data-submit=""
					onClick={changeLanguageFc}>
					{t('Submit')}
				</button>
			</main>
		</>
	)
}
