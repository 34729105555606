import { create } from 'zustand'
import { sendMessage } from '../api/message'
import { Message } from '../utils/types/message'

export interface MessageStoreState {
	message: Message[]
	getMessage: (id: string) => Promise<void>
	sendMessage: (message: string, chatId: string) => Promise<void>
}

export const messageStore = create<MessageStoreState>((set) => ({
	message: [],
	getMessage: async (id) => {
		// const message = await getMessage(id)

		set((state) => ({
			...state,
			message: [
				...state.message,
				{
					id: 'dbb78707-0e28-4e23-b658-dc67f30d4b34',
					createdAt: '2024-10-27T11:33:31.123Z',
					updatedAt: '2024-10-14T10:10:22.987Z',
					chatId: 'dbb78707-0e28-4e23-b658-dc67f30d4b34',
					message: 'get fuck out here !',
					chatUserId: 'dbb78707-0e28-4e23-b658-dc67f30d4b33'
				},
				{
					id: 'dbb78707-0e28-4e23-b658-dc67f30d4b35',
					createdAt: '2024-10-27T11:33:31.123Z',
					updatedAt: '2024-10-14T10:10:22.987Z',
					chatId: 'dbb78707-0e28-4e23-b658-dc67f30d4b35',
					message: 'ok what gonna do ?',
					chatUserId: 'dbb78707-0e28-4e23-b658-dc67f30d4b36'
				}
			]
		}))
	},

	sendMessage: async (res, chatId) => {
		const message = await sendMessage(res, chatId)

		set((state) => {
			return {
				...state,
				message: [...state.message, message]
			}
		})
	}
}))
