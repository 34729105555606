import { useState } from 'react'
import MaskInput from 'react-maskinput'
import { authStore } from '../store/auth'
import { Modal } from 'antd'
import '../styles/Profile.scss'
import { useTranslation } from 'react-i18next'

export const ChangeNumberModal = ({
	handleNumber,
	setHandleNumber
}: {
	handleNumber: boolean
	setHandleNumber: any
}) => {
	const { t } = useTranslation()
	const { user, changeUserNumber, isAuthChangesDisabled } =
		authStore()

	const [number, setNumber] = useState<string>(
		user?.loginphone as string
	)
	const [errorMessage, setErrorMessage] = useState<string>('')

	const isChangingDisabled = isAuthChangesDisabled.find(
		(number) => number.type === 'numberChange'
	)

	const onCancelNumberFc = () => {
		if (!user) return
		setHandleNumber(false)
		setNumber(user.loginphone)
		setErrorMessage('')
	}

	const changeUserNumberSubmitFc = async () => {
		if (user?.loginphone === number) return setHandleNumber(false)

		const phonePattern = /^\+998\(\d{2}\)\d{3}-\d{2}-\d{2}$/
		if (phonePattern.test(number)) {
			changeUserNumber({ loginphone: number })
			setHandleNumber(false)
			setErrorMessage('')
		} else {
			setErrorMessage('Number should be complete !')
		}
	}

	const ChangingNumber = (e: any) => {
		setNumber(e.target.value)
		setErrorMessage('')
	}

	return (
		<Modal
			open={handleNumber}
			onCancel={() => setHandleNumber(false)}
			centered
			footer={
				<div className="cabinetModalBt">
					<button data-submit="" onClick={onCancelNumberFc}>
						{t('Cancel')}
					</button>
					<button
						data-submit=""
						type="submit"
						onClick={changeUserNumberSubmitFc}
						disabled={!!isChangingDisabled}>
						{t('Submit')}
					</button>
				</div>
			}>
			<div className="cabinetModals">
				<div className="cabinetTextInput">
					<p>{t('PhoneNumber')}</p>
					<MaskInput
						mask="+998(00)000-00-00"
						onChange={ChangingNumber}
						value={number}
					/>
					<span className="cabinetNamesError">
						{errorMessage}
					</span>
				</div>
			</div>
			<div>
				{isChangingDisabled
					? t('NumberChangingBlockedOpensIn') +
						' ' +
						new Date(
							new Date(
								isChangingDisabled.nextAvailableTime
							)
						).toLocaleString()
					: ''}
			</div>
		</Modal>
	)
}
