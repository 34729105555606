import { create } from 'zustand'
// import { getChat } from '../api/chat'
import { Chat } from '../utils/types/chat'

export interface ChatStoreState {
	chat: Chat[]
	getChat: () => Promise<void>
}

export const chatStore = create<ChatStoreState>((set) => ({
	chat: [],
	getChat: async () => {
		if (chatStore.getState().chat.length) return
		console.log('fetch')
		set({
			chat: [
				{
					id: 'dbb78707-0e28-4e23-b658-dc67f30d4b34',
					createdAt: '2024-10-14T08:22:31.123Z',
					updatedAt: '2024-10-14T09:10:22.987Z',
					lastMessage: 'get a fuck out here!',
					chatUsers: [
						{
							userId: 'd32f5268-2a56-49e5-a03d-0d2b47784a6c',
							role: 'PROVIDER',
							firstName: 'John',
							lastName: 'Doe',
							isOnline: true
						},
						{
							userId: 'bc74fd93-5c77-4c79-872a-826c15fbde6a',
							role: 'ADMINISTRATION',
							firstName: 'Jane',
							lastName: 'Smith',
							isOnline: true
						}
					],
					chatMessageUnreadedCount: 5
				},
				{
					id: 'dbb78707-0e28-4e23-b658-dc67f30d4b35',
					createdAt: '2024-10-14T08:22:31.123Z',
					updatedAt: '2024-10-14T09:10:22.987Z',
					lastMessage: 'ok what gonna do ?',
					chatUsers: [
						{
							userId: 'd32f5268-2a56-49e5-a03d-0d2b47784a6c',
							role: 'PROVIDER',
							firstName: 'Baris',
							lastName: 'Johnson',
							isOnline: true
						},
						{
							userId: 'bc74fd93-5c77-4c79-872a-826c15fbde6a',
							role: 'ADMINISTRATION',
							firstName: 'Jane',
							lastName: 'Smith',
							isOnline: true
						}
					],
					chatMessageUnreadedCount: 4
				}
			]
		})
	}
}))
