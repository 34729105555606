import arrowLeft from '../icons/arrowLeftTip.svg'
import arrowRight from '../icons/arrowRightTip.svg'
import { useNavigate } from 'react-router-dom'
import { HOME_ROUTE } from '../utils/routenames'
import { Flex } from 'antd'
import { useTranslation } from 'react-i18next'

const Tip = ({ onAction }: { onAction: () => void }) => {
	const { t } = useTranslation()

	const navigate = useNavigate()
	const closeWindow = () => {
		onAction()
		return navigate(HOME_ROUTE)
	}

	return (
		// <Flex data-tipforhome="" justify="center" vertical>
		<div data-tipforhome="">
			<Flex justify="space-between">
				<img src={arrowLeft} alt="123" />
				<img src={arrowRight} alt="123" />
			</Flex>
			<p
				style={{
					flex: 1,
					textAlign: 'center',
					paddingTop: 10
				}}>
				{t('ToGetInLine')}
				<br />
				{t('UseCodeQRScanner')}
			</p>
			<p className="QANew">
				<button
					data-submit=""
					onClick={closeWindow}
					style={{ width: 'auto', margin: 'auto' }}
					className="QANewBtn">
					<i className="las la-thumbs-up la-2x"></i>
					{t('AllClear')}
				</button>
			</p>
		</div>
		// </Flex>
	)
}

export default Tip
