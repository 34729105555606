import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { queueSchemeStore } from '../store/queueScheme'
import { QueueScheme } from '../utils/types/queueScheme'
import { useTranslation } from 'react-i18next'
import { Recepient } from '../utils/types/recepient'
import '../styles/QueueSchemeOne.scss'
import { formatTime } from '../utils/format-date'
import { Modal, Tooltip } from 'antd'
import { Helmet } from 'react-helmet'

export const QueueSchemeOne: FC = () => {
	const { id } = useParams<string>()
	const { t } = useTranslation()
	const skipWaitTime = 59
	const noMoreRecTime = 3

	const {
		queueScheme,
		pause,
		continueFc,
		getQueueSchemeOne,
		nextFc,
		activate,
		isPauseButtonDisabled,
		isPauseAvailable
	} = queueSchemeStore()

	const [qs, setQs] = useState<QueueScheme>()
	const [isNoMoreRecepient, setIsNoMoreRecepient] = useState(false)
	const [isSkipBtnDisabled, setIsSkipBtnDisabled] = useState(false)
	const [skipTime, setSkipTime] = useState(-1)
	const [noMoreRecep, setNoMoreRecep] = useState(-1)
	const [pauseModal, setPauseModal] = useState(false)
	const [pauseEndModal, setPauseEndModal] = useState(false)
	const [qsFetched, setQsFetched] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			await getQueueSchemeOne(id!).then(() =>
				setQsFetched(true)
			)
		}
		fetchData()
	}, [id, getQueueSchemeOne, queueScheme])

	useEffect(() => {
		isPauseAvailable(id!)
	}, [isPauseAvailable, id])

	useEffect(() => {
		if (qsFetched) setQs(queueScheme.find((qsc) => qsc.id === id))
	}, [queueScheme, id, qsFetched])

	useEffect(() => {
		if (!qs?.queues || !qs.queues?.length) return
		let countdown: NodeJS.Timeout
		const interval =
			(+new Date() -
				+new Date(
					qs.queues[0].recepients[0]?.start as Date
				)) /
			1000

		if (interval < skipWaitTime) {
			countdown = setInterval(() => {
				setSkipTime(+(skipWaitTime - interval).toFixed())
				setIsSkipBtnDisabled(true)
			}, 1000)
		}
		if (interval > skipWaitTime) {
			setSkipTime(0)
			setIsSkipBtnDisabled(false)
		}
		return () => clearInterval(countdown)
	}, [qs, skipTime])

	if (!qs || !qsFetched) return <></>

	const nextFunction = async () => {
		const data = await nextFc(
			qs.id,
			qs.queues[0].id,
			qs.organizationId
		)

		setIsSkipBtnDisabled(true)
		setSkipTime(skipWaitTime)
		setNoMoreRecep(noMoreRecTime)

		if (isRecepient(data)) {
			setIsNoMoreRecepient(false)
			if (data.number) {
				console.log('Recepient number:', data.number)
			} else {
				console.log(
					'Recepient does not have a number property'
				)
			}

			let countdown = setInterval(() => {
				setSkipTime((prev) => {
					if (prev <= 1) {
						clearInterval(countdown)
						setIsSkipBtnDisabled(false)
						return 0
					}
					return prev - 1
				})
			}, 1000)
		} else {
			setIsNoMoreRecepient(true)
			const timerId = setInterval(() => {
				setNoMoreRecep((prev) => {
					if (prev <= 1) {
						clearInterval(timerId)
						setIsNoMoreRecepient(false)

						return 0
					}
					return prev - 1
				})
			}, 1000)
		}
	}

	// Type guard function
	function isRecepient(
		data: Recepient | { message: string }
	): data is Recepient {
		return 'number' in data
	}

	const pauseFunction = async () => {
		await pause(qs.id, qs.queues[0].id, true, qs.organizationId)
		setPauseEndModal(false)
	}
	const continueFunction = async () => {
		await continueFc(qs.id, qs.queues[0].id, qs.organizationId)
	}

	const activateFc = async () => {
		await activate(
			qs.id,
			qs.queues[0].id,
			qs.queues[0].recepients[0].id
		)
	}

	const endFunction = async () => {
		// await functionw(qs.id, qs.queues[0].id)
		setPauseEndModal(false)
	}

	const pauseModalFC = () => {
		setPauseEndModal(true)
		setPauseModal(true)
	}

	const exitModalFC = () => {
		setPauseEndModal(true)
		setPauseModal(false)
	}

	return (
		<>
			<Helmet>
				<title>{qs.name} – Queue Expert</title>
				<meta name="description" content={t('QueueDesc')} />
			</Helmet>
			<header className="QSTopTitle">{t('Queue')}</header>
			<main className="QSmain">
				<div className="QSName">{qs?.name}</div>
				<div className="QSDataMain">
					{qs.queues.length ? (
						<div className="QSDataIconTop">
							<div className="QSQueueNum">
								<i className="lar la-user la-2x icon"></i>
								<div className="QSData">
									<div className="QSTopNames">
										{t('Queue')}
									</div>
									<div className="QSBottomNum">
										{qs.recepientsCount} чел
									</div>
								</div>
							</div>
							<div className="QSData">
								<div className="QSTopNamesLeft">
									{t('OpeningHours')}
								</div>
								<div className="QSBottomNum">
									{formatTime(
										qs.queues[0].startTime,
										'HH:mm'
									)}{' '}
									-{' '}
									{formatTime(
										qs.queues[0].finishTime,
										'HH:mm'
									)}
								</div>
								{/* <div className="QSBreakTime">
								обед 13:00-14:00
							</div> */}
							</div>
						</div>
					) : (
						<></>
					)}

					<div className="QSDataIcon QSDataIcon-noBorder">
						<i className="lar la-clock la-2x icon"></i>
						<div className="QSData">
							<div className="QSTopNames">
								{t('Note')}
							</div>
							<div className="QSBottomNum">
								{qs.description}
							</div>
						</div>
					</div>
					{qs.queues.length ? (
						<div className="QSCurrent">
							<div className="QSCurText">
								{t('Current')} №
							</div>
							<div className="QSCurNumber">
								{qs.active ?? t('Free')}
							</div>
						</div>
					) : (
						<div className="QSCurrent">
							<p className="todayNotWork">
								{t('TodayNotWork')}
							</p>
						</div>
					)}
				</div>

				{qs.queues.length ? (
					<div className="queueSchemeBt">
						{qs.queues[0].status === 'active' ? (
							qs.queues[0].recepients[0]?.status ===
							'calling' ? (
								<div className="QSHereSkip">
									<button
										className="QSHereSkipBt green"
										onClick={activateFc}>
										{t('Here')}
									</button>
									<button
										disabled={isSkipBtnDisabled}
										className={` red ${isSkipBtnDisabled ? 'QSButtonNext' : 'QSHereSkipBt'}`}>
										{skipTime === -1
											? t('Loading')
											: skipTime > 0
												? skipTime + ' sek'
												: t('Skip')}
									</button>
								</div>
							) : (
								<button
									className="QSButtonNext"
									onClick={nextFunction}
									disabled={isNoMoreRecepient}>
									<i
										className={`las la-${!isNoMoreRecepient ? 'arrow-right' : 'frown disabled'} la-2x iconNext`}></i>
									{!isNoMoreRecepient
										? t('Next')
										: t('NoMoreRecepients') +
											` (${noMoreRecep})`}
								</button>
							)
						) : (
							<></>
						)}
						<div className="QSBtPauseEnd">
							<Modal
								onCancel={() =>
									setPauseEndModal(false)
								}
								closable={false}
								centered={true}
								open={pauseEndModal}
								footer={
									<div className="QSButtonModal">
										<button
											data-submit=""
											className="red"
											onClick={() =>
												setPauseEndModal(
													false
												)
											}>
											<>
												<i className="las la-times la-2x"></i>
												{t('Cancel')}
											</>
										</button>
										<button
											data-submit=""
											className="yellow"
											onClick={
												pauseModal
													? pauseFunction
													: endFunction
											}>
											<i
												className={`las la-${pauseModal ? 'hourglass' : 'times'} la-2x`}></i>
											{pauseModal
												? t('Pause')
												: t('End')}
										</button>
									</div>
								}>
								<p>
									{t('DoYouWantTo')}{' '}
									{pauseModal
										? t('PauseThis')
										: t('EndThis')}
								</p>
							</Modal>
							{qs.queues[0].status === 'active' ? (
								<Tooltip
									title={
										isPauseButtonDisabled.disabled
											? t(
													'PauseBlockedNextIn'
												) +
												' ' +
												new Date(
													isPauseButtonDisabled?.nextAvailableTime!
												).toLocaleTimeString()
											: ''
									}>
									<button
										className="QSButton yellow"
										onClick={pauseModalFC}
										disabled={
											isPauseButtonDisabled.disabled
										}>
										<i className="lar la-hourglass la-2x"></i>
										{t('Pause')}
									</button>
								</Tooltip>
							) : qs.queues[0].status === 'paused' ? (
								<button
									className="QSButton green"
									onClick={continueFunction}>
									<i className="las la-play la-2x"></i>
									{t('Continue')}
								</button>
							) : (
								<></>
							)}

							<button
								className="QSButton red"
								onClick={exitModalFC}>
								<i className="las la-times la-2x"></i>
								{t('End')}
							</button>
						</div>
					</div>
				) : (
					<></>
				)}
			</main>
		</>
	)
}
