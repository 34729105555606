import { create } from 'zustand'
import { getLanguage } from '../api/language'
import { Language } from '../utils/types/language'

export interface LanguageStoreState {
	language: Language[]
	getLanguage: () => Promise<void>
}

export const languageStore = create<LanguageStoreState>((set) => ({
	language: [],
	getLanguage: async () => {
		const language = await getLanguage()
		set({ language })
	}
}))
