import { useTranslation } from 'react-i18next'
import { authStore } from '../store/auth'
import { Link } from 'react-router-dom'
import {
	CHANGE_LANGUAGE_ROUTE,
	MONITORING_ROUTE
} from '../utils/routenames'
import '../styles/Profile.scss'
import IconVer from '../icons/iconVerified.png'
import { FC, useState } from 'react'
import { ChangeNumberModal } from '../components/ChangeNumberModal'
import '../styles/Profile.scss'
import { ChangeUserInfoModal } from '../components/ChangeUserInfoModal'
import { ChangeUserNameModal } from '../components/ChangeUserNameModal'
import { Helmet } from 'react-helmet'

export const Profile: FC = () => {
	const { t } = useTranslation()
	const { user, uploadImage } = authStore()

	const [image, setImage] = useState<string>()
	const [handleNumber, setHandleNumber] = useState<boolean>(false)
	const [handleModal, setHandleModal] = useState<boolean>(false)
	const [handleUName, setHandleUName] = useState<boolean>(false)

	// const history = useHistory()

	const logOut = () => {
		// user.setUser({})
		// user.setName()
		// user.setlastVisit()
		// user.setIsAuth(false)
		// localStorage.clear()
		// history.push(LOGIN_ROUTE)
		// document.body.scrollTop =
		// 	document.documentElement.scrollTop = 0
	}

	const uploadImageFc = async (images: FileList | null) => {
		if (!images?.length) return
		const formData = new FormData()
		formData.append('avatar', images[0])

		await uploadImage(formData)

		setImage(URL.createObjectURL(images[0]))
	}

	if (!user) return <></>

	return (
		<>
			<ChangeNumberModal
				handleNumber={handleNumber}
				setHandleNumber={setHandleNumber}
			/>

			<ChangeUserInfoModal
				handleModal={handleModal}
				setHandleModal={setHandleModal}
			/>

			<ChangeUserNameModal
				handleUName={handleUName}
				setHandleUName={setHandleUName}
			/>
			<Helmet>
				<title>@{user.username} – Queue Expert</title>
				<meta name="description" content={t('ProfileDesc')} />
			</Helmet>
			<header>
				<h2 className="mainTopTitle">{t('Profile')}</h2>
			</header>
			<main className="CabMain">
				<div className="cabinetRow">
					{user.avatar ? (
						<label className="cabinetImgContainer">
							<img
								className="cabinetImg"
								src={
									image
										? image
										: `https://ewp-test.s3.ap-southeast-1.amazonaws.com/${user.avatar}`
								}
								alt={user.firstName + "'s photo"}
							/>
							<input
								hidden
								type="file"
								accept="image/*"
								onChange={(e) =>
									uploadImageFc(e.target.files)
								}
							/>
							<div className="cabinetFile">
								<i className="las la-camera"></i>
							</div>
						</label>
					) : (
						<i className="las la-user-tie la-3x"></i>
					)}
					<div className="cabinetRowText">
						<div>{t('UserInfo')}</div>
						<div
							className="cabinetUser text-capitalize"
							onClick={() => setHandleModal(true)}>
							{user.firstName} {user.middleName}{' '}
							{user.lastName}
						</div>
					</div>
				</div>
				<div className="cabinetRow">
					<i className="la la-user la-2x"></i>
					<div className="cabinetRowText">
						<div>{t('Username')}</div>
						<div
							className="cabinetUser"
							style={{
								display: 'flex',
								alignItems: 'center'
							}}
							onClick={() => setHandleUName(true)}>
							@{user.username}
							{user.isVerified ? (
								<img
									style={{
										marginLeft: '3px',
										width: '18px',
										height: '18px'
									}}
									src={IconVer}
									alt=""
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
				<div className="cabinetRow">
					<i className="las la-mobile la-2x "></i>
					<div className="cabinetRowText dark">
						{t('PhoneNumber')}:
					</div>
					<div
						className="cGreen"
						onClick={() => setHandleNumber(true)}>
						{user.loginphone}
					</div>
				</div>
				{/* <div className="cabinetRow dark pl-4">
					<div>{t('Tariff')}:</div>
					<div className="cGreen">FREE</div>
				</div> */}
				{/* <div>
					<button
						submit=""
						className="cGray"
						// onClick={() =>
						// 	history.push(CHANGE_TARIF_ROUTE)
						// }
					>
						{t('ChangeTariff')}
					</button>
				</div> */}
				<Link
					className="center cabinetRow"
					to={MONITORING_ROUTE}>
					<i className="la la-history la-2x"></i>
					<div className="cabinetRowText flexGrow dark">
						<div>{t('MonitoringQueues')}</div>
					</div>
					<i className="la la-1x la-angle-right"></i>
				</Link>
				<Link
					to={CHANGE_LANGUAGE_ROUTE}
					className="center cabinetRow">
					<i className="las la-language la-2x"></i>
					<div className="cabinetRowText flexGrow dark">
						{t('ChangeLanguage')}
					</div>
					<i className="la la-1x la-angle-right"></i>
				</Link>

				{/* <ModalPasswordForm /> */}
				<div className="buttonExit">
					<button
						className="cRed buttonExitText"
						data-submit=""
						onClick={logOut}>
						{t('Exit')}
					</button>
				</div>
			</main>
		</>
	)
}
