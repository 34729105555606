import { $authHost } from '.'

export const getMessage = async (id: string) => {
	const { data } = await $authHost.get(`message/user/${id}`)
	return data
}

export const sendMessage = async (
	message: string,
	chatId: string
) => {
	// const { data } = await $authHost.post('message/' + message)
	// return data as Message
	return {
		id: '203b00f6-28e5-4bdb-bb8d-f7cf71515cc4',
		createdAt: new Date().toJSON(),
		updatedAt: new Date().toJSON(),
		chatId,
		message,
		chatUserId: '203b00f6-28e5-4bdb-bb8d-f7cf71515cc4'
	}
}
