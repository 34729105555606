import { create } from 'zustand'
import {
	getOneQueueSchemeFromProvider,
	getQueueSchemes,
	pause,
	continueFc,
	nextFc,
	activateFc,
	getQueueSchemesOrg,
	getOneQueueSchemeFromProviderOrg,
	pauseOrg,
	continueOrgFc,
	nextOrgFc,
	isPauseAvailable
} from '../api/queueScheme'
import { QueueScheme } from '../utils/types/queueScheme'
import { Recepient } from '../utils/types/recepient'

export interface QueueSchemeState {
	queueScheme: QueueScheme[]
	fetchedQueueSchemes: string[]
	fetchedQueueSchemeOrgs: string[]
	isPauseButtonDisabled: {
		disabled: boolean
		nextAvailableTime?: Date
	}

	getQueueSchemes: () => Promise<void>
	getQueueSchemesOrg: (organizationId: string) => Promise<void>
	getQueueSchemeOne: (queueScheme: string) => Promise<void>
	getOneQueueSchemeFromProviderOrg: (
		organizationId: string,
		queueSchemeId: string
	) => Promise<void>
	pause: (
		queueSchemeId: string,
		queueId: string,
		deactivate: boolean,
		organizationId?: string
	) => Promise<void>

	isPauseAvailable: (queueSchemeId: string) => Promise<void>

	continueFc: (
		queueSchemeId: string,
		queueId: string,
		organizationId?: string
	) => Promise<void>
	nextFc: (
		queueSchemeId: string,
		queueId: string,
		organizationId: string
	) => Promise<
		| Recepient
		| {
				message: 'QueueNotFound' | 'NoMoreRecepients'
		  }
	>
	activate: (
		queueSchemeId: string,
		queueId: string,
		recepientId: string
	) => Promise<void>
	setQueueStatus: (
		data: {
			queueSchemeId: string
			queueId: string
		},
		status: string
	) => void

	setNextFc: (data: {
		active: string | null
		next: null | Recepient
		previous: string | null
		queueId: string
		queueSchemeId: string
	}) => void

	setActivateFc: (data: { queueSchemeId: string }) => void

	setQueueClientJoinFc: (data: { queueSchemeId: string }) => void

	setCancelClientFc: (data: {
		queueSchemeId: string
		recepientId: string
	}) => void

	setSkipClientFc: (data: {
		queueSchemeId: string
		recepientId: string
		skipping: boolean
	}) => void
}

export const queueSchemeStore = create<QueueSchemeState>((set) => ({
	queueScheme: [],
	fetchedQueueSchemes: [],
	fetchedQueueSchemeOrgs: [],
	isPauseButtonDisabled: {
		disabled: false
	},
	getQueueSchemes: async () => {
		const queueScheme = await getQueueSchemes()
		set((state) => {
			return {
				...state,
				queueScheme: [...state.queueScheme, ...queueScheme]
			}
		})
	},
	getQueueSchemesOrg: async (organizationId) => {
		if (!organizationId) return
		if (
			queueSchemeStore
				.getState()
				.fetchedQueueSchemeOrgs.includes(organizationId)
		) {
			return
		}

		queueSchemeStore
			.getState()
			.fetchedQueueSchemeOrgs.push(organizationId)

		const resQueueScheme: QueueScheme[] =
			await getQueueSchemesOrg(organizationId)
		set((state) => {
			const queueScheme = [
				...state.queueScheme,
				...resQueueScheme
			]
			return {
				...state,
				queueScheme //: [
				// 	...new Set(
				// 		queueScheme.map((qs) => {
				// 			console.log(qs)
				// 			return JSON.stringify(qs)
				// 		})
				// 	)
				// ].map((qs) => JSON.parse(qs))
			}
		})
	},

	getQueueSchemeOne: async (qsId) => {
		if (!qsId) return
		if (
			queueSchemeStore
				.getState()
				.fetchedQueueSchemes.includes(qsId)
		)
			return
		const queueScheme = await getOneQueueSchemeFromProvider(qsId)

		set((state) => {
			state.fetchedQueueSchemes.push(qsId)
			const qs = state.queueScheme.find((qs) => qs.id === qsId)
			if (qs) {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueScheme.id) {
						return queueScheme
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData
				}
			} else {
				state.queueScheme.push(queueScheme)

				return state
			}
		})
	},
	getOneQueueSchemeFromProviderOrg: async (
		organizationId,
		queueSchemeId
	) => {
		if (!queueSchemeId) return
		if (
			queueSchemeStore
				.getState()
				.fetchedQueueSchemes.includes(queueSchemeId)
		)
			return
		const queueScheme = await getOneQueueSchemeFromProviderOrg(
			organizationId,
			queueSchemeId
		)

		set((state) => {
			state.fetchedQueueSchemes.push(queueSchemeId)
			const qs = state.queueScheme.find(
				(qs) => qs.id === queueSchemeId
			)
			if (qs) {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueScheme.id) {
						return queueScheme
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData
				}
			} else {
				state.queueScheme.push(queueScheme)

				return state
			}
		})
	},

	pause: async (
		queueSchemeId,
		queueId,
		deactivate,
		organizationId
	) => {
		let pauseAvailable: {
			nextAvailableTime?: Date
			remainingClicks: number
		}

		if (organizationId) {
			pauseAvailable = await pauseOrg(
				queueSchemeId,
				queueId,
				deactivate,
				organizationId
			)
		} else {
			pauseAvailable = await pause(
				queueSchemeId,
				queueId,
				deactivate
			)
		}

		if (
			pauseAvailable.remainingClicks > -1 &&
			!pauseAvailable?.nextAvailableTime
		) {
			set((state) => {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueSchemeId) {
						return {
							...qs,
							queues: [
								{ ...qs.queues[0], status: 'paused' }
							]
						}
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData
				}
			})
		} else if (
			pauseAvailable.nextAvailableTime &&
			pauseAvailable.remainingClicks <= -1
		) {
			set((state) => {
				return {
					...state,
					isPauseButtonDisabled: {
						disabled: true,
						nextAvailableTime:
							pauseAvailable.nextAvailableTime as Date
					}
				}
			})
		}
	},

	isPauseAvailable: async (queueSchemeId) => {
		const data = await isPauseAvailable(queueSchemeId)
		set((state) => {
			return {
				...state,
				isPauseButtonDisabled: {
					disabled: 'nextAvailableTime' in data,
					nextAvailableTime: data.nextAvailableTime
				}
			}
		})
	},

	continueFc: async (queueSchemeId, queueId, organizationId) => {
		if (organizationId) {
			await continueOrgFc(
				queueSchemeId,
				queueId,
				organizationId
			)
		} else {
			await continueFc(queueSchemeId, queueId)
		}

		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						queues: [
							{ ...qs.queues[0], status: 'active' }
						]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},
	nextFc: async (queueSchemeId, queueId, organizationId) => {
		let recepient: Recepient
		if (organizationId) {
			recepient = await nextOrgFc(queueId, organizationId)
		} else {
			recepient = await nextFc(queueId)
		}

		if (!recepient) return { message: 'QueueNotFound' }

		if ('message' in recepient) {
			if (recepient.message === 'NoMoreRecepients') {
				set((state) => {
					const newData = state.queueScheme.map((qs) => {
						if (qs.id === queueSchemeId) {
							return {
								...qs,
								active: null,
								queues: [
									{
										...qs.queues[0],
										recepients: []
									}
								]
							}
						}
						return qs
					})
					return {
						...state,
						queueScheme: newData
					}
				})
			}
			return recepient
		}

		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						active: recepient.number,
						queues: [
							{
								...qs.queues[0],
								recepients: [recepient]
							}
						]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})

		return recepient
	},

	setNextFc: ({
		active,
		next,
		previous,
		queueId,
		queueSchemeId
	}) => {
		if (!next || !active || next === null) {
			set((state) => {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueSchemeId) {
						return {
							...qs,
							active: null,
							queues: [
								{
									...qs.queues[0],
									recepients: []
								}
							]
						}
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData
				}
			})
		} else {
			set((state) => {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueSchemeId) {
						return {
							...qs,
							active,
							recepientsCount: qs.recepientsCount - 1,
							queues: [
								{
									...qs.queues[0],
									recepients: [next]
								}
							]
						}
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData
				}
			})
		}
	},

	activate: async (queueSchemeId, queueId, recepientId) => {
		await activateFc(queueSchemeId, queueId, recepientId)
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						queues: [
							{
								...qs.queues[0],
								recepients: [
									{
										...qs.queues[0].recepients[0],
										status: 'active'
									}
								]
							}
						]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},

	setActivateFc: ({ queueSchemeId }) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						queues: [
							{
								...qs.queues[0],
								recepients: [
									{
										...qs.queues[0].recepients[0],
										status: 'active'
									}
								]
							}
						]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},

	setQueueStatus: async ({ queueId, queueSchemeId }, status) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (
					qs.queues &&
					qs.id === queueSchemeId &&
					qs.queues[0].id === queueId
				) {
					return {
						...qs,
						queues: [{ ...qs.queues[0], status }]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},

	setQueueClientJoinFc: async ({ queueSchemeId }) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						recepientsCount: +qs.recepientsCount + 1
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},
	setCancelClientFc: async ({ queueSchemeId, recepientId }) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId && !qs.queues?.length) {
					return {
						...qs,
						recepientsCount: +qs.recepientsCount - 1
					}
				}
				if (
					qs.id === queueSchemeId &&
					qs.queues.length &&
					!qs.queues[0].recepients.length
				) {
					return {
						...qs,
						recepientsCount: +qs.recepientsCount - 1
					}
				}

				if (
					qs.id === queueSchemeId &&
					qs.queues[0].recepients.length
				) {
					const newData = qs.queues[0].recepients.filter(
						(r) => r.id !== recepientId
					)
					return {
						...qs,
						active: null,
						queues: [
							{
								...qs.queues[0],
								recepients: newData
							}
						]
					}
				}

				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},

	setSkipClientFc: async ({
		queueSchemeId,
		recepientId,
		skipping
	}) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (
					qs.id === queueSchemeId &&
					qs.queues[0].recepients.length
				) {
					return {
						...qs,
						recepientsCount: skipping
							? qs.recepientsCount
							: +qs.recepientsCount - 1,
						active: null,
						queues: [
							{
								...qs.queues[0],
								recepients: []
							}
						]
					}
				}
				return qs
			})

			return {
				...state,
				queueScheme: newData
			}
		})
	}
}))
