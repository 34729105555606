import { CSSProperties, FC } from 'react'

export const NavbatLogoSmall: FC<{ style?: CSSProperties }> = ({
	style
}) => {
	return (
		<svg
			width="84"
			height="71"
			viewBox="0 0 84 71"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}>
			<path
				d="M41.7501 34.3892C33.8586 34.3892 27.4605 26.6908 27.4605 17.1947C27.4605 7.69836 29.5614 0 41.7501 0C53.9392 0 56.0397 7.69836 56.0397 17.1947C56.0395 26.6908 49.6417 34.3892 41.7501 34.3892Z"
				fill="url(#paint0_linear_15_907)"
			/>
			<path
				d="M14.7914 59.949C15.0557 43.4061 17.2366 38.6924 33.9248 35.7084C33.9248 35.7084 36.2743 38.6738 41.7497 38.6738C47.225 38.6738 49.5741 35.7084 49.5741 35.7084C66.0803 38.6595 68.3942 43.3036 68.6983 59.4114C68.723 60.7267 68.7344 60.7958 68.739 60.643C68.7381 60.9292 68.7368 61.4582 68.7368 62.3811C68.7368 62.3811 64.7636 70.316 41.7495 70.316C18.7353 70.316 14.7617 62.3811 14.7617 62.3811C14.7617 61.7883 14.7613 61.3758 14.7608 61.0956C14.7653 61.19 14.7746 61.0069 14.7914 59.949Z"
				fill="url(#paint1_linear_15_907)"
			/>
			<path
				d="M21.9196 31.3191C15.5099 31.3191 10.314 25.0667 10.314 17.3541C10.314 9.64123 12.02 3.38883 21.9196 3.38883C23.5848 3.38883 25.0176 3.56633 26.2503 3.89767C23.9637 8.07361 23.6504 13.1458 23.6504 17.1945C23.6504 21.7787 24.9047 26.1553 27.2147 29.7792C25.6269 30.7615 23.8278 31.3191 21.9196 31.3191Z"
				fill="url(#paint2_linear_15_907)"
			/>
			<path
				d="M0.0247388 52.0782C0.23982 38.6425 2.0108 34.8141 15.5648 32.3905C15.5648 32.3905 17.4728 34.7992 21.9198 34.7992C22.1037 34.7992 22.282 34.7936 22.4575 34.7857C19.632 36.0478 17.0616 37.8051 15.1686 40.3765C11.8962 44.822 11.1438 50.7938 10.9885 59.5669C1.86971 57.7817 0.000866417 54.0539 0.000866417 54.0539C0.000866417 53.5681 0.000860326 53.2348 0 53.007C0.00365638 53.0872 0.0111887 52.9435 0.0247388 52.0782Z"
				fill="url(#paint3_linear_15_907)"
			/>
			<path
				d="M61.5808 31.3191C59.6724 31.3191 57.8737 30.7615 56.2853 29.7794C58.5953 26.1556 59.8496 21.7789 59.8496 17.1947C59.8496 13.1458 59.5363 8.0736 57.2502 3.89788C58.4826 3.56654 59.9152 3.38904 61.5808 3.38904C71.4804 3.38904 73.186 9.64144 73.186 17.3543C73.186 25.0667 67.99 31.3191 61.5808 31.3191Z"
				fill="url(#paint4_linear_15_907)"
			/>
			<path
				d="M61.0431 34.7855C61.2182 34.7934 61.3965 34.7989 61.5808 34.7989C66.0279 34.7989 67.9358 32.3903 67.9358 32.3903C81.4894 34.8139 83.2608 38.6421 83.4755 52.078C83.4895 52.9435 83.4966 53.0869 83.5002 53.0066C83.4998 53.2344 83.4994 53.5677 83.4994 54.0535C83.4994 54.0535 81.6303 57.7813 72.5121 59.5665C72.3562 50.7936 71.6043 44.8218 68.3314 40.3761C66.4387 37.8051 63.8686 36.0476 61.0431 34.7855Z"
				fill="url(#paint5_linear_15_907)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_15_907"
					x1="72.5237"
					y1="9.90366"
					x2="19.5279"
					y2="66.5429"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#C0E91C" />
					<stop offset="1" stopColor="#73CC2C" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_15_907"
					x1="72.5237"
					y1="9.90366"
					x2="19.5279"
					y2="66.5429"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#C0E91C" />
					<stop offset="1" stopColor="#73CC2C" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_15_907"
					x1="72.5237"
					y1="9.90366"
					x2="19.5279"
					y2="66.5429"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#C0E91C" />
					<stop offset="1" stopColor="#73CC2C" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_15_907"
					x1="72.5237"
					y1="9.90366"
					x2="19.5279"
					y2="66.5429"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#C0E91C" />
					<stop offset="1" stopColor="#73CC2C" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_15_907"
					x1="72.5237"
					y1="9.90366"
					x2="19.5279"
					y2="66.5429"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#C0E91C" />
					<stop offset="1" stopColor="#73CC2C" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_15_907"
					x1="72.5237"
					y1="9.90366"
					x2="19.5279"
					y2="66.5429"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#C0E91C" />
					<stop offset="1" stopColor="#73CC2C" />
				</linearGradient>
			</defs>
		</svg>
	)
}
