import { FC, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { authStore } from '../store/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOME_ROUTE } from '../utils/routenames'
import { AxiosError } from 'axios'

const Login: FC = () => {
	const { t } = useTranslation()
	const { login } = authStore()
	const navigate = useNavigate()
	const location = useLocation()
	const [loginData, setLoginData] = useState({
		username: '',
		password: ''
	})

	const onFinish = async (values: typeof loginData) => {
		try {
			await login(values.username, values.password)

			navigate(location.search.split('?path=')[1] || HOME_ROUTE)
		} catch (err: any) {
			if (err instanceof AxiosError) {
				alert(err.response?.data.message)
			} else {
				console.log(err)
			}
		}
	}

	return (
		<div
			className="h-page"
			style={{
				backgroundColor: '#F9FBFF'
			}}>
			<Form onFinish={onFinish}>
				<Form.Item name="username">
					<Input
						type="username"
						value={loginData.username}
						prefix={'asd'}
						onChange={(e) =>
							setLoginData({
								...loginData,
								username: e.target.value
							})
						}
					/>
				</Form.Item>
				<Form.Item name="password">
					<Input
						type="password"
						value={loginData.password}
						prefix={'asd'}
						onChange={(e) =>
							setLoginData({
								...loginData,
								password: e.target.value
							})
						}
					/>
				</Form.Item>
				<Button
					type="primary"
					htmlType="submit"
					style={{
						width: '100%'
					}}>
					{t('Login')}
				</Button>
			</Form>
		</div>
	)
}

export default Login
