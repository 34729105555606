import { Flex, Form } from 'antd'
import { FC } from 'react'
import { SearchSubmit } from '../icons/searchSubmit'
import { NavbatLogoSmall } from '../icons/NavbatLogoSmall'

export const Search: FC = () => {
	const onFocused = (e: any) => {
		e.target.classList.add('focused')
		const formSearch = e.target.closest('form')
		const svgIcon = formSearch.querySelector('label svg')
		const qrSeIcon = formSearch.querySelector('i.la-qrcode')
		qrSeIcon.classList.add('hideFocus')
		svgIcon.classList.add('focused')
		svgIcon.children[0].setAttribute(
			'fill',
			'url(#paint0_linear_15:841)'
		)
	}
	const onBlured = (e: any) => {
		e.target.classList.remove('focused')
		const formSearch = e.target.closest('form')
		const svgIcon = formSearch.querySelector('label svg')
		const qrSeIcon = formSearch.querySelector('i.la-qrcode')
		qrSeIcon.classList.remove('hideFocus')
		svgIcon.classList.remove('focused')
		svgIcon.children[0].setAttribute('fill', '#979791')
	}
	return (
		<Flex vertical className="search" gap={20}>
			<Flex
				justify="center"
				align="center"
				gap={10}
				style={{ marginTop: 20 }}>
				<NavbatLogoSmall style={{ width: 38, height: 32 }} />
				<Flex vertical>
					<p className="searchNavbatText">Navbat</p>
					<p className="searchKutmangText">Kutmang!</p>
				</Flex>
			</Flex>
			<Form className="searchInputComp">
				<label className="searchInput">
					<input
						placeholder="Enter the code"
						onFocus={onFocused}
						onBlur={onBlured}
					/>
					<div className="searchInputSubmit">
						<SearchSubmit clicked={false} />
					</div>
				</label>
				<Flex className="searchQR" align="center">
					<i className="la la-qrcode la-2x"></i>
				</Flex>
			</Form>
		</Flex>
	)
}
