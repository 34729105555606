import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import '../styles/ChatOne.scss'
import icon from '../icons/iconVerified.png'
import { ChatOneInput } from '../components/ChatOneInput'
import { chatStore } from '../store/chat'
import { Chat } from '../utils/types/chat'
import { messageStore } from '../store/message'
import { Message } from '../utils/types/message'
import { useTranslation } from 'react-i18next'

export const ChatOne: FC = () => {
	const { id } = useParams<string>()

	const { t } = useTranslation()

	const { chat } = chatStore()
	const { sendMessage, message, getMessage } = messageStore()

	const [chatOne, setChatOne] = useState<Chat>()
	const [messages, setMessages] = useState<
		Array<Message & { marginTop: string; day: boolean }>
	>([])
	const [chatMessageValue, setChatMessageValue] = useState('')

	const messagesEndRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!id) return
		getMessage(id)
	}, [getMessage, id])

	const sendMessageFC = () => {
		if (!chatMessageValue.trim()) return
		sendMessage(chatMessageValue, id!)
		setChatMessageValue('')
	}

	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			sendMessageFC()
		}
	}

	const getFormattedDate = (date: Date): string => {
		const formatter = new Intl.DateTimeFormat(
			localStorage.getItem('language')!,
			{
				month: 'long',
				day: 'numeric'
			}
		)
		return formatter.format(date)
	}
	useEffect(() => {
		setMessages(
			message
				.filter((m) => m.chatId === id) // FIX THIS there is problem with date coming from filter probably
				.reduce((acc: any, msg: any, index: number) => {
					if (index > 0) {
						const prevMessage = message[index - 1]
						const currentTime = new Date(msg.createdAt)
						const prevTime = new Date(
							prevMessage?.createdAt
						)
						const timeDifference =
							(currentTime.getTime() -
								prevTime.getTime()) /
							(1000 * 60)
						const dayDifference =
							timeDifference / (60 * 24)

						if (
							dayDifference >= 1 ||
							new Date(
								currentTime.setHours(0, 0, 0, 0)
							) > prevTime
						) {
							msg.day = true
							msg.marginTop = '5px'
						} else {
							msg.day = false
							if (timeDifference > 15) {
								msg.marginTop = '20px'
							} else msg.marginTop = '5px'
						}
					} else {
						msg.day = true
						msg.marginTop = '5px'
					}

					acc.push(msg)
					return acc
				}, [])
		)
	}, [id, message])

	useEffect(() => {
		setChatOne(chat.find((c) => c.id === id))
	}, [chat, id])

	useEffect(() => {
		messagesEndRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'start'
		})
	}, [messages])

	if (!chatOne || !id) return <></>

	return (
		<>
			<header className="ChatOneHeader">
				<i
					className="las la-long-arrow-alt-left la-2x ChatOneBackicon"
					onClick={() => window.history.back()}></i>
				<div className="ChatOneImgStatus">
					<div className="ChatOneImgCon">
						<img
							className="ChatOneImg"
							src={icon}
							alt="background"
						/>
						<div className="ChatOneStatus"></div>
					</div>
				</div>
				<div className="ChatOneNameActive">
					<div className="ChatOneName">
						{chatOne.chatUsers
							.map(
								(c) => c.firstName + ' ' + c.lastName
							)
							.join(', ')}
					</div>
					<div className="ChatOneActive">{t('Online')}</div>
				</div>
				<div className="ChatOneIcons">
					<i className="las la-search la-2x"></i>
					<i className="las la-ellipsis-v la-2x"></i>
				</div>
			</header>
			<main className="ChatOneMain">
				<div className="ChatOneMessages">
					{messages.map((a, i) => (
						<Fragment key={i}>
							{a.day ? (
								<div className="ChatOneDay">
									<div className="ChatOneDate">
										{getFormattedDate(
											new Date(a.createdAt)
										)}
									</div>
								</div>
							) : (
								''
							)}
							<span
								className={'ChatOneMes sent'}
								style={{
									marginTop: a.marginTop
								}}>
								<div>{a.message}</div>
								<div className="ChatOneMesTime">
									{new Date(a.createdAt).getHours()}
									:
									{new Date(
										a.createdAt
									).getMinutes()}
								</div>
							</span>
							<div ref={messagesEndRef} />
						</Fragment>
					))}
				</div>
			</main>
			<footer className="ChatOneFooter">
				<div className="ChatOneFtIcon">
					<i className="las la-paperclip la-2x"></i>
				</div>
				<div className="ChatOneFtInput">
					<ChatOneInput
						chatMessageValue={chatMessageValue}
						setChatMessageValue={setChatMessageValue}
						handleKeyDown={handleKeyDown}
					/>
				</div>
				<div
					className="ChatOneFtSend"
					onClick={sendMessageFC}>
					<i className="lar la-paper-plane la-2x ChatOneBackIcon2"></i>
				</div>
			</footer>
		</>
	)
}
