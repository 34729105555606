import axios from 'axios'

const $host = axios.create({
	baseURL: process.env.REACT_APP_API_URL
})

const $authHost = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true
	// headers: {
	// 	'Accept-Navbat-Language': localStorage.getItem('lang')
	// }
})

const authInterceptor = (config: any) => {
	// const
	// if()
	return config
}

$authHost.interceptors.request.use(authInterceptor)

export { $host, $authHost }
