import {
	Navigate,
	Route,
	Routes,
	useLocation
} from 'react-router-dom'
import { publicRoutes, clientRoutes, loginRoutes } from '../routes'
import { FC } from 'react'
import { LOGIN_ROUTE, NOT_FOUND_ROUTE } from '../utils/routenames'

interface AppRouterProps {
	isLoggedIn: boolean
}

export const AppRouter: FC<AppRouterProps> = ({ isLoggedIn }) => {
	const location = useLocation()

	const checkForAllPages = (
		path: string,
		redirect: string,
		Element: FC
	) => {
		if (path === '*') {
			return (
				<Route
					key={'*'}
					path={'*'}
					element={<Navigate to={redirect} />}
				/>
			)
		} else {
			return (
				<Route key={path} path={path} element={<Element />} />
			)
		}
	}

	return (
		<Routes location={location}>
			{[
				...publicRoutes,
				...(isLoggedIn ? clientRoutes : []),
				...(!isLoggedIn ? loginRoutes : [])
			].flatMap(({ path, Element }) =>
				checkForAllPages(
					path,
					!isLoggedIn ? LOGIN_ROUTE : NOT_FOUND_ROUTE,
					Element
				)
			)}
		</Routes>
	)
}
